import { prefetchRecentlyPlayed } from '@/api/games/queries/useGamesRecentlyPlayed/prefetch';
import { prefetchTopPayouts } from '@/api/games/queries/useTopPayouts/prefetch';
import { prefetchUnifiedBets } from '@/api/unified-bets/queries/useUnifiedBets/prefetch';
import HomeComponent from '@/components/Home/V3';
import { getBiggestWinsByEndDate } from '@/components/Home/V3/Carousels/BiggestWins/Filter/helper';
import { BiggestWinFilterOptions } from '@/components/Home/V3/Carousels/BiggestWins/Filter/props';
import { Language } from '@/enums/language';
import { GetServerSidePropsResultWithMetadataAndServerState } from '@/interfaces/pages/getServerSidePropsResultWithMetadataAndDehydratedState';
import { SSRCookies } from '@/interfaces/ssr-cookies.interface';
import { generateCasinoMetadata } from '@/lib/metadata';
import { mainPrefetchs } from '@/lib/prefetch';
import { parsedServerCookies } from '@/utils/cookies';
import {
  blockedCountries,
  devWhitelistedIps,
  whitelistedIps,
} from '@/utils/middleware/handlers/middelwareBlockedCountriesHandler';
import { dehydrate, QueryClient } from '@tanstack/react-query';
import { GetServerSidePropsContext } from 'next';

export default function HomePage() {
  return <HomeComponent />;
}

export async function getServerSideProps({
  locale,
  req,
  res,
}: GetServerSidePropsContext): Promise<GetServerSidePropsResultWithMetadataAndServerState> {
  const queryClient = new QueryClient();
  const cookies = parsedServerCookies(req?.headers?.cookie);
  const metadata = await generateCasinoMetadata({
    lang: locale as Language,
    path: '/',
  });
  const cfIp = req?.headers?.['cf-connecting-ip'] as string | undefined;
  const cfCountry = req?.headers?.['cf-ipcountry'] as string | undefined;

  const isProduction = process.env.NODE_ENV === 'production';
  const isDevEnvironment = req.headers.host?.includes('dev.ice.game');

  const whitelistedResolvedIps = isDevEnvironment
    ? devWhitelistedIps
    : whitelistedIps;

  const isWhitelisted = whitelistedResolvedIps.includes(cfIp || '');

  const shouldRedirect = isDevEnvironment
    ? !isWhitelisted
    : !isWhitelisted &&
      isProduction &&
      blockedCountries.includes(cfCountry || '');

  if (shouldRedirect) {
    const destination = isDevEnvironment
      ? `https://ice.game/${locale}`
      : `/${locale}/region-unavailable?country=${cfCountry ?? ''}`;
    return {
      redirect: {
        destination,
        permanent: false,
      },
    };
  }

  const { endDate, startDate } = getBiggestWinsByEndDate(
    BiggestWinFilterOptions['1Day'],
  );

  if (shouldRedirect) {
    return {
      redirect: {
        destination: `/region-unavailable?country=${cfCountry ?? ''}`,
        permanent: false,
      },
    };
  }

  await Promise.all([
    prefetchUnifiedBets(queryClient, {} as SSRCookies, {
      limit: 20,
      sortBy: 'multiplier',
      startDate: startDate.toISOString() as any,
      endDate: endDate.toISOString() as any,
    }),
    prefetchTopPayouts(queryClient, {} as SSRCookies),
    prefetchRecentlyPlayed(queryClient, cookies),
    mainPrefetchs({
      queryClient,
      req,
    }),
  ]);

  return {
    props: {
      metadata: JSON.stringify(metadata),
      dehydratedState: dehydrate(queryClient),
    },
  };
}
