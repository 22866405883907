import {
  Button,
  ErrorCard,
  TableGrid,
  TransWithDefault,
} from '@starsoft/common/components';
import styles from './styles.module.scss';
import CarousselBiggestWinsIcon from '../Icon';
import useTranslation from 'next-translate/useTranslation';
import { useIsMobile, useLottiePlayer } from '@starsoft/common/hooks';
import { useUnifiedBets } from '@/api/unified-bets/queries/useUnifiedBets';
import { useMemo } from 'react';
import { getBiggestWinsByEndDate } from '../Filter/helper';
import { BiggestWinFilterOptions } from '../Filter/props';
import { luckiestWinsColumns } from '@/constants/luckiestWins';
import Link from '@/components/core/Link';
import { UnifiedBet } from '@/models/bet/unified';
import { TableGridColumn } from '@starsoft/common/src/components/TableGrid/props';
import { withLazyLoad } from '@starsoft/common/hoc';

function LuckiestWinsCardVersion() {
  const { t } = useTranslation('common');
  const { lottieAnimationRef } = useLottiePlayer({
    path: '/roulette-cards-v3.json',
  });
  const isMobile: boolean = useIsMobile();
  const columns: TableGridColumn<UnifiedBet>[] = useMemo(
    () => (isMobile ? luckiestWinsColumns.slice(1) : luckiestWinsColumns),
    [isMobile],
  );
  const { endDate, startDate } = useMemo(
    () => getBiggestWinsByEndDate(BiggestWinFilterOptions['1Day']),
    [],
  );
  const { bets, isLoading, refetch, error } = useUnifiedBets({
    limit: 3,
    sortBy: 'multiplier',
    order: 'DESC',
    startDate,
    endDate,
  });

  return (
    <div className={styles.container}>
      <div className={styles.container__header}>
        <CarousselBiggestWinsIcon />
        <TransWithDefault i18nKey="common:luckiest_wins_title" />
      </div>
      <div className={styles.container__card}>
        <div
          className={`${styles.container__card__wrapper} ${styles['container__card__wrapper--remove-overflow']}`}
        >
          <div
            ref={lottieAnimationRef}
            className={styles.container__card__wrapper__lottie}
          />
          <h3 className={styles.container__card__wrapper__heading}>
            {t('luckiest_wins_card_heading')}
          </h3>
          <Link href="/casino">
            <Button
              useMaxContent
              className={styles.container__card__wrapper__button}
            >
              {t('casino:play')}
            </Button>
          </Link>
        </div>
        <div className={styles.container__card__wrapper}>
          {(error && <ErrorCard error={error} refetch={refetch} />) || (
            <TableGrid
              columns={columns}
              rows={bets?.data ?? []}
              isLoading={isLoading}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default withLazyLoad(LuckiestWinsCardVersion);
